import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { BASE_PATH } from '@gentext/api-finance-client';
import { LoggingModule } from '@gentext/logging';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AlertsBannerComponent } from './alerts-banner/alerts-banner.component';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './material.module';
const apiBaseUrl = `https://${process.env['NX_API_FINANCE_HOST']}`;
const apiScopes = [
  'openid',
  'profile',
  `https://${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.onmicrosoft.com/read_license/license`,
];
// important: these are case sensitive
const apiPaths = [
  'Billing/License',
  'Billing/Activate',
  'Billing/Resolve',
  'Billing/GetPortalUrl',
  'Billing/CreateCheckoutSession',
  'Admin/GetAllLicenses',
  'Admin/DeleteLicense',
  'UsageRecord',
];
const protectedResourceMap = new Map<string, Array<string>>();

apiPaths.forEach((a) =>
  protectedResourceMap.set(`${apiBaseUrl}/${a}`, apiScopes),
);
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HomeComponent,
    ConfirmationComponent,
    AdminUsersComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AlertsBannerComponent,
    MaterialModule,
    BrowserAnimationsModule,
    LoggingModule.forRoot({
      consoleEnabled: process.env['NX_CONSOLE_ENABLED'] === 'true',
    }),
    FormsModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clientId: process.env['NX_AUTH_FINANCE_CLIENT_ID']!,
          authority: `https://${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.b2clogin.com/${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: window.location.origin,
          knownAuthorities: [
            `${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.b2clogin.com`,
          ],
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: [
            'openid',
            'profile',
            `https://${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.onmicrosoft.com/read_license/license`,
          ],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap,
      },
    ),
  ],
  providers: [
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS, // Provides as HTTP Interceptor
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: BASE_PATH,
      useValue: apiBaseUrl,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
