import { Component, OnInit } from '@angular/core';
import {
  BillingService,
  CheckoutRequestPlan,
  CreateCheckoutSessionRequestDto,
  LicenseDto,
} from '@gentext/api-finance-client';
import { LoggingService } from '@gentext/logging';

import { BillingUiService } from '../billing-ui.service';
import { UserService } from '../user.service';

@Component({
  selector: 'finance-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  loading = false;
  hasError = false;
  license: LicenseDto | undefined;
  userIsAdmin = false;
  viewPlans = false;
  hoverPremium = false;
  isYearlyChecked = false;
  email: string | undefined;
  CheckoutRequestPlan = CheckoutRequestPlan;

  isAdmin() {
    this.email = this.userService.userEmail;
    this.userIsAdmin =
      this.email?.toLocaleLowerCase().endsWith('@mentalnote.ai') ?? false;
  }

  get isFreePlan() {
    return this.license?.planId === 'free';
  }
  constructor(
    private billingService: BillingService,
    private billingUiService: BillingUiService,

    private userService: UserService,
    private logging: LoggingService,
  ) {}

  ngOnInit(): void {
    this.isAdmin();
    this.getLicense();
  }
  getLicense() {
    this.loading = true;
    this.hasError = false;
    this.billingService
      .billingLicenseGet()

      .subscribe({
        next: (license: LicenseDto) => {
          this.license = license;
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (err: any) => {
          this.logging.exception(err);
          this.loading = false;
          this.hasError = true;
        },
      });
  }

  upgrade(plan: CheckoutRequestPlan) {
    if (this.license?.planId !== 'free' || this.license?.hasPendingInvoice) {
      this.manage();
      return;
    }
    const request: CreateCheckoutSessionRequestDto = {
      plan,
      webBaseUrl: `${window.location.origin}/confirmation`,
      customerId: this.license?.customerId,
    };
    const newWindow = window.open();
    if (newWindow === null) {
      this.hasError = true;
      return;
    }
    this.loading = true;
    this.billingService
      .billingCreateCheckoutSessionPost(request)

      .subscribe({
        next: (response) => {
          this.loading = false;
          newWindow.location = response.url;
        },
        error: (err) => {
          this.logging.exception(err);
          this.loading = false;
          this.hasError = true;
        },
      });
  }

  viewGenTextPlans() {
    this.viewPlans = !this.viewPlans;
  }

  manage() {
    this.hasError = false;
    if (!this.license) {
      this.hasError = true;
      return;
    }
    this.billingUiService.managePlan().catch(() => {
      this.hasError = true;
    });
  }

  contactUs() {
    window.open('mailto:support@thefinscribe.com');
  }
}
