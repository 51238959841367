import { Component } from '@angular/core';
import { UserService } from './user.service';

@Component({
  selector: 'finance-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  get user() {
    return this.userService.user;
  }
  signOut() {
    this.userService.signOut();
  }
  constructor(private userService: UserService) {}
}
