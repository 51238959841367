<header class="my-6 w-full">
  <div class="flex flex-row items-center justify-evenly gap-4">
    <img src="assets/logo.svg" class="w-12" />
    <div *ngIf="user" class="mt-6 flex flex-row gap-4 text-center align-middle">
      <span class="block">Welcome {{ user.name }}</span>
      <fluent-button (click)="signOut()" appearance="accent"
        >Sign out</fluent-button
      >
    </div>
  </div>
</header>

<router-outlet *ngIf="user"></router-outlet>
<div id="sharing-screen" class="mb-14 mt-4"></div>
