<div class="m-auto w-full px-10 pt-10 text-center 2xl:w-3/4">
  <finance-alerts-banner [license]="license"></finance-alerts-banner>
  <fluent-progress-ring
    *ngIf="loading"
    class="mx-auto mt-4"
  ></fluent-progress-ring>
  <p class="text-red-500" *ngIf="hasError; else tmplNoError">
    Something went wrong retrieving your data, please try again later.
  </p>
  <ng-template #tmplNoError>
    <ng-container *ngIf="license">
      <h1 class="text-3xl">Your license</h1>
      <p>Plan: {{ license.planId }}</p>
      <p>
        Words left:
        <span *ngIf="license.isUnlimited; else tmplWordsLeft">&infin;</span>
        <ng-template #tmplWordsLeft>
          {{ license.isUnlimited ? '&infin; ' : license.wordsLeft }}
        </ng-template>
      </p>
      <div class="mt-10 flex flex-col items-center justify-center">
        <div class="flex-row-buttons">
          <fluent-button (click)="viewGenTextPlans()" appearance="accent"
            >Upgrade</fluent-button
          >
          <fluent-button
            (click)="manage()"
            appearance="accent"
            *ngIf="!isFreePlan"
            >Manage subscription</fluent-button
          >
        </div>

        <ng-container *ngIf="viewPlans">
          <div class="custom-checkbox">
            <input
              id="status"
              type="checkbox"
              name="status"
              [(ngModel)]="isYearlyChecked"
            />
            <label for="status">
              <div
                class="status-switch"
                data-unchecked="Monthly"
                data-checked="Annual"
              ></div>
            </label>
          </div>
          <div class="flex flex-row items-stretch text-left">
            <!-- Premium Plan Card -->
            <mat-card
              class="group box-border flex w-80 flex-col justify-between space-y-4 rounded-3xl bg-gray-300 p-5 transition-colors duration-1000 ease-in-out hover:bg-blue-700"
            >
              <div class="text-2xl group-hover:text-white">Premium</div>
              <p class="text-2xl group-hover:text-white">
                <ng-container *ngIf="!isYearlyChecked">USD 49</ng-container>
                <ng-container *ngIf="isYearlyChecked">USD 490</ng-container>
              </p>

              <div class="rounded-lg bg-white px-8 py-3">
                <div class="flex h-[170px] flex-col">
                  <span>Free tier features + </span>
                  <ul class="list-disc">
                    <li>50,000 words per month</li>
                    <li>
                      US Fundamental Data: Income Statement, Balance Sheet, Cash
                      Flow and Earnings
                    </li>
                    <li>Document upload</li>
                  </ul>
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="!isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.PremiumMonthly)"
                >
                  Get Premium
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.PremiumYearly)"
                >
                  Get Premium
                </button>
              </div>
            </mat-card>

            <!-- Business Plan Card -->
            <mat-card
              class="group box-border flex w-80 flex-col justify-between space-y-4 rounded-3xl bg-gray-300 p-5 transition-colors duration-1000 ease-in-out hover:bg-blue-700"
            >
              <div class="text-2xl group-hover:text-white">Business</div>
              <p class="text-2xl group-hover:text-white">
                <ng-container *ngIf="!isYearlyChecked">USD 99</ng-container>
                <ng-container *ngIf="isYearlyChecked">USD 990</ng-container>
              </p>

              <div class="rounded-lg bg-white px-8 py-3">
                <div class="flex h-[170px] flex-col">
                  <span>Premium Tier features +</span>
                  <ul class="list-disc">
                    <li>Forex Data</li>
                    <li>Commodities Price Data</li>
                  </ul>
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="!isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.BusinessMonthly)"
                >
                  Get Business
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  *ngIf="isYearlyChecked"
                  (click)="upgrade(CheckoutRequestPlan.BusinessYearly)"
                >
                  Get Business
                </button>
              </div>
            </mat-card>

            <!-- Enterprise Plan Card -->
            <mat-card
              class="group box-border flex w-80 flex-col justify-between space-y-4 rounded-3xl bg-gray-300 p-5 transition-colors duration-1000 ease-in-out hover:bg-blue-700"
            >
              <div class="text-2xl group-hover:text-white">Enterprise</div>
              <p class="text-2xl group-hover:text-white">Custom Quote</p>
              <div class="rounded-lg bg-white px-8 py-3">
                <div class="flex h-[170px] flex-col">
                  <ul class="ml-2 list-disc">
                    <li>Unlimited number of words</li>
                    <li>Global Stock Price and Fundamentals data</li>
                    <li>Custom integrations</li>
                  </ul>
                </div>
                <button
                  mat-raised-button
                  color="primary"
                  class="mt-2.5 box-border w-full rounded-full bg-gray-500 p-2.5 text-white group-hover:bg-blue-700"
                  (click)="contactUs()"
                >
                  Contact us
                </button>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="userIsAdmin">
      <hr class="mb-6 mt-10" />
      <h1 class="text-xl">ADMIN - All users usage overview</h1>
      <finance-admin-users
        class="graph mt-6 block w-full"
      ></finance-admin-users>
    </ng-container>
  </ng-template>
</div>
