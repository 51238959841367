/**
 * Gentext.ApiFinance, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LicenseSort = 'Words' | 'Email' | 'PlanId';

export const LicenseSort = {
    Words: 'Words' as LicenseSort,
    Email: 'Email' as LicenseSort,
    PlanId: 'PlanId' as LicenseSort
};

