import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable({ providedIn: 'root' })
export class UserService {
  get userEmail(): string | undefined {
    return this.user?.idTokenClaims
      ? (this.user.idTokenClaims['email'] as string)
      : undefined;
  }
  get user() {
    const allAccounts = this.msalService.instance.getAllAccounts();
    // we only want accounts for our authority, and not e.g. historic accounts from pre B2C
    const accounts = allAccounts.filter(
      (a) =>
        a.environment.toLowerCase() ===
        `${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.b2clogin.com`.toLowerCase(),
    );
    if (accounts.length > 0) {
      if (accounts.length > 1) {
        this.logging.trace({
          message: 'More than one account found! Using first account',
          properties: { accounts },
          severityLevel: SeverityLevel.Warning,
        });
      }

      this.msalService.instance.setActiveAccount(accounts[0]);
      return accounts[0];
    }
    return undefined;
  }

  signOut() {
    this.msalService.instance.logout();
  }
  constructor(
    private msalService: MsalService,
    private logging: LoggingService,
  ) {}
}
